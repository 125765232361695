import type { EdgeStyles } from '~/types/storyblok/shared'

export function getEdgeClasses(edgeStyle: EdgeStyles) {
  switch (edgeStyle) {
    case 'teeth':
      return 'teeth'
    case 'scallop':
      return 'scallop'
    case 'wave':
      return 'wave'
    case 'none':
    default:
      return null
  }
}
